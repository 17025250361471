import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import GetInTouch from "../../components/get-in-touch";
import RelatedProducts from "../../components/related-products";

interface Feature {
	title: string;
	description: string;
	viewBox: string;
	icon: string;
}

const TravelReservationSystemPage = () => {
	const { t } = useTranslation();

	const FEATURES: Feature[] = [
		{
			title: t("Dynamic Packaging"),
			description: t("Booking is possible as package or by flight"),
			viewBox: "0 0 750 800",
			icon:
				"M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z",
		},
		{
			title: t("Advanced Customer Login"),
			description: t("DEEP provides seamless experience on DEEP customer"),
			viewBox: "0 0 750 800",
			icon:
				"M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z",
		},
		{
			title: t("Local Hotel Inventory"),
			description: t("Direct contracted hotels can be managed"),
			viewBox: "0 0 550 600",
			icon:
				"M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z",
		},
		{
			title: t("Markup and Commission Rule Engine"),
			description: t("Booking,package,component,provider,location"),
			viewBox: "0 0 750 800",
			icon:
				"M384 320H256c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM192 32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C211.98 300.98 232.4 288 256 288h.28L192 175.51V128h224V64H192V32zM608 0H480c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32z",
		},
		{
			title: t("Seamless White Label Booking Site"),
			description: t("Mobile responsive, SEO optimized"),
			viewBox: "0 0 750 800",
			icon:
				"M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z",
		},
		{
			title: t("Flexible Payment Services"),
			description: t("DEEP can process the following"),
			viewBox: "0 0 750 800",
			icon:
				"M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z",
		},
		{
			title: t("Reseller Distribution"),
			description: t("Create organizations and configure product"),
			viewBox: "0 0 750 800",
			icon:
				"M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-33.5 53.5-3.8 127.9 58.8 136.4 4.5.6 9.1.9 13.7.9 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.8 33.1 4.7 0 9.2-.3 13.7-.9 62.8-8.4 92.6-82.8 59-136.4zM529.5 288c-10 0-19.9-1.5-29.5-3.8V384H116v-99.8c-9.6 2.2-19.5 3.8-29.5 3.8-6 0-12.1-.4-18-1.2-5.6-.8-11.1-2.1-16.4-3.6V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32V283.2c-5.4 1.6-10.8 2.9-16.4 3.6-6.1.8-12.1 1.2-18.2 1.2z",
		},
		{
			title: t("Real-Time Commercial, Financial and Operations Dashboards"),
			description: t("Top management and revenue managers"),
			viewBox: "0 0 700 750",
			icon:
				"M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z",
		},
		{
			title: t("Call Center and Intranet Application"),
			description: t("Bookings can be easily searched"),
			viewBox: "0 0 650 700",
			icon:
				"M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z",
		},
	];

	interface AddOn {
		title: string;
		description: string;
	}

	interface KeyBenefit {
		title: string;
		description: string;
	}

	const ADD_ONS: AddOn[] = [
		{
			title: t(`Dynamic "Deals"`),
			description: t("Based on the customer search analysis"),
		},
		{
			title: t("Destination and Blog Platform"),
			description: t("Unqiue content is importam from marketing"),
		},
		{
			title: t("Promotion Code Management System"),
			description: t("Whether it is a single user unique"),
		},
		{
			title: t("Customer Relationship Management"),
			description: t("The system collects detailed information"),
		},
		{
			title: t("Mobile Application"),
			description: t("Native iOS and Android Mobile"),
		},
		{
			title: t("Claims and Feedback Handling Module"),
			description: t("User claims are tracked"),
		},
	];

	const KEY_BENEFITS: KeyBenefit[] = [
		{
			title: t("Short Time to Market"),
			description: t("We deploy the environment by using ready-to-use"),
		},
		{
			title: t("Low TCO"),
			description: t("Optimized system infrastructure using the latest virtualization"),
		},
		{
			title: t("Highly Automated Operations"),
			description: t("Operated hundred percent in cloud providing"),
		},
		{
			title: t("Packaging API for B2B and B2C"),
			description: t("Access to DEEP with our Packaging API is simple"),
		},
		{
			title: t("Industry Leading Integrated Providers"),
			description: t("DEEP has interfaces to leading providers providing more than"),
		},
		{
			title: t("Flexible Ancillary Products"),
			description: t("Its easy to add more products"),
		},
	];

	const getInTouchArray: string[] = [
		t(
			"Do you already have a system migration plan where you need a solid system that is good for strong online presence?"
		),
		t(
			"Would you like to start your online travel business and you need a startup system that can be upscaled when the business grows?"
		),
		t("Do you already have a business and would like to extend it with additional travel components?"),
	];

	const data = useStaticQuery(graphql`
		query TravelReservationSystemPageQuery {
			allFile(filter: { relativePath: { in: ["banner/product_deep_reservation.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

	const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;

	return (
		<Layout>
			<SEO title="Products" />
			<div className="text-brand-grayt">
				{/* Banner */}
				<BackgroundImage Tag="div" fluid={fluidBanner}>
					<div className="container text-white h-400px sm:pt-16">
						<div className="md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 highlight">
							<h1 className="pt-12 text-3xl sm:text-4xl leading-tight font-bold">
								<div>DEEP</div>
								<span>
									<Trans i18nKey="Travel Reservation System">Travel Reservation System</Trans>
								</span>
							</h1>
							<p className="text-lg mt-5 pb-24">
								<span>
									<Trans i18nKey="We believe in ready-to-use software products">
										We believe in ready-to-use software products combined with enterprise grade
										custom development service. That’s why we are keen to build convenient software
										solutions for our airline, tour operator and OTA partners.
									</Trans>
								</span>
							</p>
						</div>
					</div>
				</BackgroundImage>
				{/* Main text */}
				<div id="mainText" className="bg-brand-gray4">
					<div className="container p-4 pt-8">
						<h1 className="text-2xl font-bold mb-6">
							<Trans>New and Clean Solution for Tour Operators and Travel Agencies</Trans>
						</h1>
						<div className="text-lg mb-4">
							<Trans i18nKey="Researching popular travel reservation products">
								Researching popular travel reservation products we have discovered the need for a new
								solution that can address several weaknesses of the market portfolio like high price,
								slow time to market, complexity, difficult operations and legacy technical platform.
								Since 2015 we’ve been relentlessly working on a new travel reservation software, DEEP,
								that can address the above issues.
							</Trans>
						</div>
						<div className="text-lg mb-4">
							<Trans i18nKey="DEEP is developed from the ground-up">
								DEEP is developed from the ground-up using the latest technologies. Our team has
								designed the product to meet the needs of the challenging eCommerce business while we
								provide exceptional support during the operation as well. It is optimized for modern
								tour operators and travel agencies with high volume online sales for managing their
								entire sales portfolio including
							</Trans>
							<ul className="list-disc ml-12 mt-1">
								<Trans i18nKey="Sales portfolio including">
									<li className="mb-1">direct sales on the website</li>
									<li className="mb-1">personal sales in the offices</li>
									<li>and the connection to re-seller agencies.</li>
								</Trans>
							</ul>
						</div>
					</div>
				</div>
				{/* Features */}
				<div id="features" className="bg-brand-gray4 pb-8">
					<div className="container p-4 pt-6 bg-white">
						<h1 className="uppercase font-bold text-2xl mb-8">
							<span className="text-brand-green mr-2">//</span>
							<Trans i18nKey="Features">Features</Trans>
						</h1>
						<div className="flex flex-wrap text-brand-grayt">
							{FEATURES.map((feature) => {
								return (
									<div className="w-full sm:w-1/2 flex" key={feature.title}>
										<div className="mt-2 mr-2">
											<svg
												className="w-14 fill-current"
												viewBox={feature.viewBox}
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d={feature.icon} />
											</svg>
										</div>
										<div>
											<div className="font-semibold text-xl">{feature.title}</div>
											<div className="mb-4 mt-2 mr-4 text-lg">{feature.description}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<GetInTouch getInTouchArray={getInTouchArray} />
				{/* Add-Ons, Key Benefits */}
				<div id="addOnsKeyBenefits" className="bg-brand-gray4 pb-8">
					<div className="container p-4 pt-8 sm:flex">
						<div className="w-full sm:w-1/2 sm:px-8">
							<h1 className="uppercase font-bold text-2xl mb-4">
								<span className="text-brand-green mr-2">//</span>
								<Trans i18nKey="Add-Ons">Add-Ons</Trans>
							</h1>
							<div className="flex flex-wrap">
								{ADD_ONS.map((addOn) => {
									return (
										<div className="w-full" key={addOn.title}>
											<div className="font-semibold text-xl">{addOn.title}</div>
											<div className="mb-4 mt-2 text-lg">{addOn.description}</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className="w-full sm:w-1/2 sm:px-8">
							<h1 className="uppercase font-bold text-2xl mb-4">
								<span className="text-brand-green mr-2">//</span>
								<Trans i18nKey="Key Benefits">Key Benefits</Trans>
							</h1>
							<div className="flex flex-wrap">
								{KEY_BENEFITS.map((keyBenefit) => {
									return (
										<div className="w-full" key={keyBenefit.title}>
											<div className="font-semibold text-xl">{keyBenefit.title}</div>
											<div className="mb-4 mt-2 text-lg">{keyBenefit.description}</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<RelatedProducts missing="deep-reservation-system" />
			</div>
		</Layout>
	);
};

export default TravelReservationSystemPage;
